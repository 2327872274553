<template>
    <div>
        <div id="touchMove" v-if="videoShow" class="suspension" @click="goVideo">
            <div>实时</div>
            <div>视频</div>
        </div>
        <router-view :magbot="magbot" />
        <div v-if="notice" class="position-bottom">
            <van-notice-bar left-icon="volume-o" text="实时告警：监测区域3 ACC值过大，监测区域8 ACC值过大" mode="closeable" @close="magbotCancel"/>
        </div>
    </div>
</template>

<script>
// import Stomp from "stompjs";
// import { MQTT_SERVICE, MQTT_USERNAME, MQTT_PASSWORD } from "@/network/configMqtt";
export default {
    data(){
        return{
            // client: Stomp.client(MQTT_SERVICE),
            magbot:'',
            videoShow:true
        }
    },
    mounted(){
        this.closePad();
        var oDiv = document.getElementById('touchMove');
        var disX,disY, moveX,moveY, L, T, starX, starY, starXEnd, starYEnd;

        oDiv.addEventListener('touchstart', function (e) {
            // e.preventDefault();
            disX = e.touches[0].clientX - this.offsetLeft;
            disY = e.touches[0].clientY - this.offsetTop;
            starX = e.touches[0].clientX;
            starY = e.touches[0].clientY;
        });
        oDiv.addEventListener('touchmove', function (e) {
            e.preventDefault();

            L = e.touches[0].clientX - disX;
            T = e.touches[0].clientY - disY;
            starXEnd = e.touches[0].clientX - starX;
            starYEnd = e.touches[0].clientY - starY;
            if (L < 0) {
                L = 0;
            } else if (L > document.documentElement.clientWidth - this.offsetWidth) {
                L = document.documentElement.clientWidth - this.offsetWidth;
            }

            if (T < 0) {
                T = 0;
            } else if (T > document.documentElement.clientHeight - this.offsetHeight) {
                T = document.documentElement.clientHeight - this.offsetHeight;
            }
            moveX = L + 'px';
            moveY = T + 'px';
            this.style.left = moveX;
            this.style.top = moveY;
        });
        window.addEventListener('touchend', function (e) {
        });
        // this.connect();
    },
    computed:{
        notice(){
            return false
            // return  window.sessionStorage.getItem('magbot') == '1' ? true : false
        }
    },
    methods:{
        goVideo(){
            if(window.localStorage.getItem('visitor') == '1'){
                this.$toast('您的身份是游客，暂无权限')
                return false;
            }
            this.$router.push({path:'/slope/video'})
        },
        // 关闭底部公告栏空间
        closePad(){
            window.sessionStorage.removeItem('magbot');
            this.magbot = 1;
        },
        // onConnected: function(frame) {
        //   console.log('--------连接成功-------')
		//   //订阅频道
	    //   const topic = "/bhms.alarm/bhms.alarm.rxbp";
	    //   this.client.subscribe(topic, this.responseCallback, this.onFailed);
	    // },
	    // onFailed: function(frame) {
	    //   console.log("MQ Failed: " + frame);
	    // },
	    // responseCallback: function(frame) {
	    //   console.log("MQ msg=>" + frame.body);
	    //   //接收消息处理
	    // },
	    // connect: function() {
	    //   //初始化mqtt客户端，并连接mqtt服务
	    //   const headers = {
	    //     login: MQTT_USERNAME,
	    //     passcode: MQTT_PASSWORD
	    //   };
        //   this.client.connect(headers, this.onConnected, this.onFailed);
        // },
        // 关闭告警栏
        magbotCancel(){
            window.sessionStorage.removeItem('magbot');
            this.magbot = 1;
        }
    },
    watch:{
    //   $route (to, from) {
    //       console.log(to)
    //       // 判断是否为当前需求页面
    //       if (to.fullPath.indexOf('/video') !== -1) {
    //         this.videoShow = false;
    //       }else {
    //         this.videoShow = true;
    //       }
    //   }
    }
}
</script>

<style lang="scss" scoped>
.suspension{
    // display: none;
    position: fixed;
    bottom: 12%;
    right: 4%;
    width: 55px;
    height: 55px;
    border: 1px solid #4bb9f9;
    border-radius: 50%;
    font-size: .85rem;
    background-color: #4bb9f9;
    color: white;
    z-index: 999;
    box-shadow:2px 2px 4px #989898;
    div:first-child{
        padding-top: 15%;
    }
}
.position-bottom{position: fixed;bottom: 8%;width: 100%;}
</style>